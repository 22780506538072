import React from 'react'
import { Link, graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    const siteTitle = '404: Not Found'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} />
        <div className="page-heading text-center text-white">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h1 className="page-content-heading pt-3">Not Found</h1>
                <p className="pt-3">
                  The page you are looking for does not exist.
                </p>
                <Link
                  class="btn btn-primary pink-btn btn-arrow text-white"
                  role="button"
                  to="/"
                  title="Home"
                >
                  Take Me Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
